import { gsap } from "gsap";

const contentsSlider = () => {
    const wrapper = document.querySelector(".contents__list");

    const slidebtn = [...document.querySelectorAll(".contents__slide__ui__item")];

    slidebtn.forEach((el) => {
        el.addEventListener("click", () => {
            const dir = el.getAttribute("data-dir") === "next" ? 1 : -1;
            const scrolled = wrapper.scrollLeft;

            const grid = document.querySelector(".contents__list__item");
            const gridSize = grid.offsetWidth;

            gsap.to(wrapper, { scrollLeft: scrolled + dir * gridSize, duration: 0.4, ease: "power2.out" });
        });
    });
};

export { contentsSlider };
