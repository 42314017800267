export default class Movie {
    constructor() {
        this.player = null;
        this.init();
    }

    init() {
        const $moviemodal = document.querySelector(".js-moviemodal");

        if ($moviemodal !== null) {
            this.setup();
            this.eventBind();
            this.toggleChange();
        }
    }

    setup() {
        const tag = document.createElement("script");

        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = () => {
            this.player = new YT.Player("player", {
                height: "360",
                width: "640",
            });
        };
    }

    eventBind() {
        const navbuttons = [...document.querySelectorAll(".js-moviemodalnav")];
        const closebuttons = [...document.querySelectorAll(".js-modal_close")];
        const openbuttons = [...document.querySelectorAll(".js-modal_open")];

        navbuttons.forEach((el) => {
            el.addEventListener("click", () => {
                document.querySelector(".js-moviemodalnav.-active").classList.remove("-active");
                el.classList.add("-active");
                const videoID = el.getAttribute("data-videoID");
                this.loadVideo(videoID);
            });
        });

        closebuttons.forEach((el) => {
            el.addEventListener("click", () => {
                this.player.stopVideo();
                navbuttons.forEach((el) => el.classList.remove("-active"));
            });
        });

        openbuttons.forEach((el) => {
            if (el.getAttribute("data-videoID")) {
                el.addEventListener("click", () => {
                    const videoID = el.getAttribute("data-videoID");
                    const currentNav = document.querySelector(`.js-moviemodalnav[data-videoID="${videoID}"]`);
                    currentNav && currentNav.classList.add("-active");
                    this.loadVideo(videoID);
                });
            }
        });
    }

    loadVideo(id) {
        //デフォルトでautoplayされる。
        this.player.loadVideoById({ videoId: id });
    }

    toggleChange() {
        const btn = [...document.querySelectorAll(".pv__slide__ui__item")];
        const txt1 = [...document.querySelectorAll(".js-pv_txt1")];
        const txt2 = [...document.querySelectorAll(".js-pv_txt2")];
        const toggleVideo = [...document.querySelectorAll(".js-toggle_video")];

        btn.forEach((el) => {
            el.addEventListener("click", () => {
                txt1.forEach((el) => el.classList.toggle("-active"));
                txt2.forEach((el) => el.classList.toggle("-active"));

                toggleVideo.forEach((el) => {
                    el.classList.add("-curtain");

                    setTimeout(() => {
                        el.classList.remove("-curtain");
                        el.classList.toggle("-active");
                    }, 500);
                });
            });
        });
    }
}
