import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class ScrollFunc {
    constructor() {
        this.init();
    }

    init() {
        this.set();
        this.onResize();
    }

    onResize() {
        window.addEventListener("resize", () => {
            ScrollTrigger.refresh();
        });
    }

    set() {
        /*
        ScrollTrigger.create({
            trigger: ".pv", //アニメーションが始まるトリガーとなる要素
            start: "top bottom",
            end: "top bottom",
            onEnter: () => {
                document.querySelector(".mv__scrollhide").classList.add("-hide");
            },
            onEnterBack: () => {
                document.querySelector(".mv__scrollhide").classList.remove("-hide");
            },
        });
        */

        gsap.to(".js-mvprrx_1", {
            yPercent: 10,
            ease: "none",
            scrollTrigger: {
                start: "top top",
                trigger: ".mv",
                scrub: true,
            },
        });

        gsap.to(".js-mvprrx_2", {
            yPercent: 20,
            ease: "none",
            scrollTrigger: {
                start: "top top",
                trigger: ".mv",
                scrub: true,
            },
        });

        gsap.to(".js-mvprrx_3", {
            xPercent: -5,
            ease: "none",
            scrollTrigger: {
                start: "top top",
                trigger: ".mv",
                scrub: true,
            },
        });
    }
}
