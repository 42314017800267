import Cookies from "js-cookie";
import { getParams } from "../../../util/getparms";

export default class Intro {
    constructor() {
        this.init();
    }

    init() {
        //intro
        const isSkip = getParams("skip") || Cookies.get("introPlayed");

        if (!isSkip) {
            this.introAnimation();
            Cookies.set("introPlayed", 1, { expires: this.getTodayExpires() });
        } else {
            this.skipAnimation();
        }
    }

    getTodayExpires() {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
    }

    introAnimation() {
        window.scrollTo(0, 0); //scrollはリセットする
        document.querySelector(".intro").classList.add("-start");
        document.body.classList.add("-lock");
        this.eventBind();

        setTimeout(() => {
            document.querySelector(".intro").classList.add("-stay");
        }, 3500);
    }

    skipAnimation() {
        document.body.classList.add("-intro_end");
        document.body.classList.remove("-lock");

        setTimeout(() => {
            document.querySelector(".mv").classList.add("-reveal");
            document.body.classList.add("-all_end");
        }, 1000);
    }

    eventBind() {
        document.querySelector(".intro__interactive").addEventListener("click", () => {
            document.querySelector(".intro").classList.remove("-start", "-stay");
            document.querySelector(".intro").classList.add("-slide_start");
            setTimeout(this.countup, 2000);

            setTimeout(() => {
                document.body.classList.add("-intro_end");
                document.body.classList.remove("-lock");
            }, 4300);

            setTimeout(() => {
                document.querySelector(".mv").classList.add("-reveal");
                document.body.classList.add("-all_end");
            }, 5500);
        });
    }

    countup() {
        let count = 2012;

        const n1 = document.querySelector(".js-intronum_1");
        const n2 = document.querySelector(".js-intronum_2");
        const n3 = document.querySelector(".js-intronum_3");
        const n4 = document.querySelector(".js-intronum_4");

        const loop = () => {
            count++;

            if (count > 2022) {
                return;
            }

            let c = String(count).split("");

            n1.classList.remove("-n0", "-n1", "-n2", "-n3", "-n4", "-n5", "-n6", "-n7", "-n8", "-n9");
            n2.classList.remove("-n0", "-n1", "-n2", "-n3", "-n4", "-n5", "-n6", "-n7", "-n8", "-n9");
            n3.classList.remove("-n0", "-n1", "-n2", "-n3", "-n4", "-n5", "-n6", "-n7", "-n8", "-n9");
            n4.classList.remove("-n0", "-n1", "-n2", "-n3", "-n4", "-n5", "-n6", "-n7", "-n8", "-n9");

            n1.classList.add(`-n${c[0]}`);
            n2.classList.add(`-n${c[1]}`);
            n3.classList.add(`-n${c[2]}`);
            n4.classList.add(`-n${c[3]}`);

            setTimeout(loop, 100);
        };

        loop();
    }
}
