export default class News {
    constructor() {
        this.init();
    }

    init() {
        this.setNewsAnimation();
        this.togglePause();
    }

    setNewsAnimation() {
        const news = document.querySelector(".mv__news");
        const newsItem = [...document.querySelectorAll(".mv__news__item")];
        let newsWidth = 0;

        newsItem.forEach((el) => {
            newsWidth += el.offsetWidth;
        });

        const v = 50; //1sで100px進む
        const t = (window.innerWidth + newsWidth) / v;

        document.documentElement.style.setProperty("--newsWidth", `${-1 * newsWidth}px`);
        document.documentElement.style.setProperty("--newsDuration", `${t}s`);
        news.classList.add("-anim");
    }

    togglePause() {
        const news = document.querySelector(".mv__news");
        const detailbtn = [...document.querySelectorAll(".js-news_detail")];
        const closebtn = [...document.querySelectorAll(".js-modal_close")];

        detailbtn.forEach((el) => {
            el.addEventListener("click", () => {
                news.style.animationPlayState = "paused";
            });
        });

        closebtn.forEach((el) => {
            el.addEventListener("click", () => {
                news.style.animationPlayState = "running";
            });
        });
    }
}
