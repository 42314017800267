//classes
import Intro from "./classes/intro";
import ScrollFunc from "./classes/scrollfunc.es6";
import News from "./classes/news.es6";
import Movie from "./classes/movie.es6";

//functions
import { contentsSlider } from "./functions/contents_slider.es6";

export default class Main {
    constructor() {
        this.init();
    }

    init() {
        this.intro = new Intro();
        this.scrollfunc = new ScrollFunc();
        this.news = new News();
        this.movie = new Movie();
        contentsSlider();
    }
}
